import React, {useState, useEffect} from "react";
import {Main} from "../styled";
import {PageHeader} from "../../components/page-headers/page-headers";
import {Card, Col, Form, Input, Row, Select, Table, DatePicker} from "antd";
import FontAwesome from "react-fontawesome";
import * as PropTypes from "prop-types";
import axios from "axios";
import "../customStyle.css";
import Helper from "../../helpers/Helper";
import {useHistory} from "react-router-dom";
import Loader from "../../components/loader";
import CISUI from "../../helpers/CISUI";
import {ApiUrl} from "../ApiUrl";

FontAwesome.propTypes = {
    icon: PropTypes.string, size: PropTypes.number,
};
const {Option} = Select;

function DepositReport() {
    const [form] = Form.useForm();
    const [state, setState] = useState({
        url: ApiUrl.DEPOSIT_LIST,
    });
    const [depositLists, setDepositLists] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        Helper.Title("Deposit Report");
    }, []);
    const getDeposit = () => {
        setLoading(true);
        console.log(state.url);
        axios
            .get(state.url)
            .then((response) => {
                console.log(response.data);
                if (response.data.status === 1) {
                    setDepositLists(response.data.data);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    useEffect(() => {
        getDeposit();
    }, [state.url]);

    const deleteDeposit = (id) => {
        if (confirm("Are you sure you want to delete this data?")) {
            setLoading(true);
            axios
                .delete(ApiUrl.DELETE_DEPOSITE + "/" + id)
                .then((res) => {
                    if (res.data.status === 0) {
                        setLoading(false);
                        CISUI().Notification("warning", res.data.msg);
                    } else {
                        const isNotId = (type) => type.id !== id;
                        const update = depositLists.filter(isNotId);
                        setDepositLists(update);
                        CISUI().Notification("success", res.data.msg);
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            return false;
        }
    };

    const history = useHistory();

    const onSearch = async (values) => {
        let qs = Helper.GetQueryStrings(values);
        history.push("?" + qs);
        const newURL = ApiUrl.DEPOSIT_LIST + "?" + qs;
        console.log(newURL);
        setState({url: newURL});
    };
  const columns = [
    {
      title: "SL No.",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Account No",
      dataIndex: "account_no",
      key: "account_no",
    },
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      key: "customer_name",
    },
    {
      title: "Description",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Deposit For",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Amount",
      dataIndex: "debit",
      key: "debit",
      align: "right",
    },
    {
      title: "Balance",
      dataIndex: "tbalance",
      key: "tbalance",
      align: "right",
    },
    {
      title: "Customer Balance",
      dataIndex: "customer_balance",
      key: "customer_balance",
      align: "right",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];
    const content = (id) => {
        return (<>
                <p>
                    <button
                        title="Delete"
                        className="btn  action_btn"
                        onClick={() => deleteDeposit(id)}
                    >
                        <i className={`fa fa-trash btn-delete`}/>
                        <span>Delete</span>
                    </button>
                </p>
            </>);
    };

    const data = [];

    depositLists.map((list, index) => {
        data.push({
            id: ++index,
            account_no: list.account_no,
            customer_name: list.customer_name,
            title: list.title,
            debit: CISUI().GetCurrencyFormat(list.debit),
            tbalance: CISUI().GetCurrencyFormat(list.tbalance),
            customer_balance: CISUI().GetCurrencyFormat(list.customer_balance),
            created_at: Helper.CreatedDateFormat(list.created_at),
            status: CISUI().PaymentStatusButton(list.status),
            action: (<>
                    <button
                        className="btn btn-dlt action_btn"
                        onClick={() => deleteDeposit(list.id)}
                    >
                        <i className={`fa fa-trash`}/>
                    </button>
                </>),
        });
    });
    return (<>
            {loading ? <Loader/> : ""}
            <PageHeader ghost title="Deposit Reports"/>
            <Main>
                <Row gutter={24}>
                    <Col
                        lg={24}
                        md={24}
                        sm={24}
                        xs={24}
                        style={{margin: "0px 0 15px 0"}}
                    >
                        <Card title="Advance Search" style={{padding: "10px"}}>
                            <Form
                                form={form}
                                name="input-form"
                                layout="vertical"
                                onFinish={onSearch}
                            >
                                <Row gutter={24}>
                                    <Col lg={6} md={6} xs={24}>
                                        <Form.Item
                                            name="account_no"
                                            label="Account No"
                                            rules={[{
                                                required: false, message: "Account No is required",
                                            },]}
                                        >
                                            <Input placeholder="Account No"/>
                                        </Form.Item>
                                    </Col>

                                    <Col lg={6} md={6} xs={24}>
                                        <Form.Item name="from_date" label="From Date">
                                            <DatePicker
                                                className="gx-w-100"
                                                format="MM-DD-YYYY"
                                                placeholder="Select Date"
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col lg={6} md={6} xs={24}>
                                        <Form.Item name="to_date" label="To Date">
                                            <DatePicker
                                                className="gx-w-100"
                                                format="MM-DD-YYYY"
                                                placeholder="Select Date"
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={2} md={2} xs={2} style={{marginTop: "24px"}}>
                                        <div style={{width: "100%", display: "flex"}}>
                                            <div className="sDash_form-action">
                                                <button
                                                    type="submit"
                                                    className="btn btn-success"
                                                    size="large"
                                                    id="submit"
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                            <div className="sDash_form-action">
                                                <button
                                                    type="submit"
                                                    className="btn btn-danger"
                                                    onClick={() => form.resetFields()}
                                                >
                                                    Reset
                                                </button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>
                    </Col>

                    <Col lg={24} md={24} sm={24} xs={24}>
                        <Card
                            title="Deposit Report"
                            extra={CISUI().AddButton("newDeposit")}
                        >
                            <Table
                                columns={columns}
                                dataSource={data}
                                bordered
                                pagination={{position: ["bottomCenter"]}}
                                scroll={{x: true}}
                            />
                        </Card>
                    </Col>
                </Row>
            </Main>
        </>);
}

export default DepositReport;