import React from 'react';
import { Row, Col } from 'antd';
import { useLocation } from 'react-router-dom'


function HeaderView() {
    const location = useLocation();
    console.log(location.pathname);
    return <span>Path : {location.pathname}</span>
}


const AuthLayout = WraperContent => {
  return () => {
      var lastRoute = HeaderView();
      if(lastRoute == 'signup') {
          return (
              <div className="container">
                  <WraperContent />
              </div>
          );
      }
      else {
          return (
              <Row>
                  <Col xxl={24} xl={24} lg={24} md={24} xs={24}>
                      <WraperContent />
                  </Col>
              </Row>
          );
      }
  };
};

export default AuthLayout;
