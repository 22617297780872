import actions from './actions';

const { GET_CUSTOMER_RUNNING_CONTRIBUTION } = actions;

const initState = {
  accountData: [],
};

/**
 *
 * @todo impure state mutation/explaination
 */
const CommonReducer = (state = initState, action) => {
  const { type, data, err } = action;

  // console.log(type, data)
  switch (type) {
    case GET_CUSTOMER_RUNNING_CONTRIBUTION:
      return {
        ...state,
        accountData: data
      };
    default:
      return state;
  }
};
export default CommonReducer;
