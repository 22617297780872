import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import MarketValueHistory from "../../container/investment/MarketValueHistory";

const NewWithdraw = lazy(() => import('../../container/withdraw/NewWithdraw'));
const WithdrawRequest = lazy(() => import('../../container/withdraw/WithdrawRequest'));
const WithdrawRequestHistory = lazy(() => import('../../container/withdraw/WithdrawRequestHistory'));
const WithdrawReport = lazy(() => import('../../container/withdraw/WithdrawReport'));

const Withdraw = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={`${path}/newWithdraw`} component={NewWithdraw} />
            <Route path={`${path}/withdrawRequest`} component={WithdrawRequest} />
            <Route path={`${path}/withdrawRequestHistory`} component={WithdrawRequestHistory} />
            <Route path={`${path}/withdrawReport`} component={WithdrawReport} />
        </Switch>
    );
};

export default Withdraw;
