class LocalStorageData {
    AppStorage = (key) => {
        if(localStorage.getItem('userData')) {
            const userData = JSON.parse(localStorage.getItem('userData'));
            return userData[key];
        }
    }

    // AppUserId = () => {
    //     return this.AppStorage('id');
    // }
    //
    // AppUserName = () => {
    //     return this.AppStorage('name');
    // }



}

export default LocalStorageData = new LocalStorageData();

