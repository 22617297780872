import React, {useEffect, useState} from "react";
import {Card, Col, Divider, Form, Input, notification, Row, Select,} from "antd";
import {Main} from "../styled";
import {useHistory} from "react-router-dom";
import axios from "axios";
import Helper from "../../helpers/Helper";
import Loader from "../../components/loader";
import {ApiUrl} from "../ApiUrl";
import CISUI from "../../helpers/CISUI";
import API from "../../config/api";

const DepositDetails = (props) => {
    const history = useHistory();
    const [form] = Form.useForm();
    const user_id = props.match.params.id;
    const [loading, setLoading] = useState(false);
    const [attachment, setAttachment] = useState([]);
    const [state, setState] = useState({});

    let redirectTo = "/admin/deposit/depositReport";

    useEffect(() => {
        Helper.Title("Deposit Details");
        getEditData()
    }, [user_id]);

    const getEditData = () => {
        setLoading(true)
        axios.get(`${ApiUrl.DEPOSIT_DETAILS}${user_id}`)
            .then((res) => {
                console.log(res.data);
                if (res.data.status === 1) {
                    form.setFieldsValue(res.data.data)
                    setState(res?.data?.data)
                    setAttachment(res?.data?.data?.attachments)
                }
                setLoading(false)
            })
            .catch((error) => {
                console.log(error);
            });
    }


    const handleApprove = async () => {

        if (confirm("Are you sure you want to approve?")) {
            setLoading(true);
            axios
                .post(ApiUrl.DEPOSIT_APPROVED + user_id, {'status': 1})
                .then((res) => {
                    console.log(res);
                    if (res.data.status === 1) {
                        history.push(redirectTo);
                        notification["success"]({
                            message: "Success.", description: res.data.msg,
                        });
                    } else {
                        notification["warning"]({
                            message: "warning.", description: res.data.msg,
                        });
                    }
                    setLoading(false);
                })
                .catch((errors) => {
                    console.log(errors);
                });
        } else {
            return false;
        }
    };

    if(loading){
        return <Loader />;
    }


    return (<>
            {/*{loading ? <Loader/> : ""}*/}

            <Main>
                <Row gutter={25}>
                    <div
                        style={{
                            width: "100%", display: "flex", justifyContent: "center",
                        }}
                    >
                        <Col xl={24} lg={24} xs={24}>
                            <Card title="Deposit Details" extra={CISUI().ListButton(redirectTo)}>
                                <Row>
                                    <Col lg={24} md={24} xs={24}>
                                        <Divider orientation="left">Customer Information</Divider>
                                        <Row>
                                            <Col lg={8} md={8} xs={24} style={{marginBottom: "10px"}}>
                                                <p style={{fontWeight: 500, marginBottom: "4px"}}>Customer Name:</p>
                                                <p style={{fontSize: "13px"}}>{state.customer_name}</p>
                                            </Col>
                                            <Col lg={8} md={8} xs={24} style={{marginBottom: "10px"}}>
                                                <p style={{fontWeight: 500, marginBottom: "4px"}}>Account No:</p>
                                                <p style={{fontSize: "13px"}}>{state.account_no}</p>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col lg={24} md={24} xs={24}>
                                        <Divider orientation="left">Deposit Details</Divider>
                                        <Row>
                                            <Col lg={8} md={8} xs={24} style={{marginBottom: "10px"}}>
                                                <p style={{fontWeight: 500, marginBottom: "4px"}}>Description:</p>
                                                <p style={{fontSize: "13px"}}>{state.title}</p>
                                            </Col>
                                            <Col lg={8} md={8} xs={24} style={{marginBottom: "10px"}}>
                                                <p style={{fontWeight: 500, marginBottom: "4px"}}>Payment Mode:</p>
                                                <p style={{fontSize: "13px"}}>{state.payment_mode}</p>
                                            </Col>
                                            <Col lg={8} md={8} xs={24} style={{marginBottom: "10px"}}>
                                                <p style={{fontWeight: 500, marginBottom: "4px"}}>Transaction /
                                                    Reference No:</p>
                                                <p style={{fontSize: "13px"}}>{state.tranx_no}</p>
                                            </Col>
                                            <Col lg={8} md={8} xs={24} style={{marginBottom: "10px"}}>
                                                <p style={{fontWeight: 500, marginBottom: "4px"}}>Transaction /
                                                    Reference Note:</p>
                                                <p style={{fontSize: "13px"}}>{state.tranx_note}</p>
                                            </Col>
                                            <Col lg={8} md={8} xs={24} style={{marginBottom: "10px"}}>
                                                <p style={{fontWeight: 500, marginBottom: "4px"}}>Deposit Amount:</p>
                                                <p style={{fontSize: "13px", fontWeight: "bold"}}>${state.amount}</p>
                                            </Col>
                                            <Col lg={8} md={8} xs={24} style={{marginBottom: "10px"}}>
                                                <p style={{fontWeight: 500, marginBottom: "4px"}}>Deposit Date:</p>
                                                <p style={{fontSize: "13px"}}>{CISUI().DateFormat(state.date)}</p>
                                            </Col>
                                            <Col lg={8} md={8} xs={24} style={{marginBottom: "10px"}}>
                                                <p style={{fontWeight: 500, marginBottom: "4px"}}>Remarks:</p>
                                                <p style={{fontSize: "13px"}}>{state.remarks}</p>
                                            </Col>
                                            <Col lg={8} md={8} xs={24} style={{marginBottom: "10px"}}>
                                                <p style={{fontWeight: 500, marginBottom: "4px"}}>Status:</p>
                                                <p style={{fontSize: "13px"}}>{CISUI().PaymentStatusButton(state.status)}</p>
                                            </Col>
                                            <Col lg={8} md={8} xs={24} style={{marginBottom: "10px"}}>
                                                <p style={{fontWeight: 500, marginBottom: "4px"}}>Approved At:</p>
                                                <p style={{fontSize: "13px"}}>{state.approved_at !== null ? CISUI().DateFormat(state.approved_at) : ''}</p>
                                            </Col>
                                            <Col lg={8} md={8} xs={24} style={{marginBottom: "10px"}}>
                                                <p style={{fontWeight: 500, marginBottom: "4px"}}>Attachments:</p>
                                                <p style={{fontSize: "13px"}}>{attachment.map((item, index) => <a
                                                    href={Object.values(API)[0].imageURL + item} target="_blank"
                                                    style={{display: "block"}}>{item.split('/')[2]}</a>)}
                                                </p>
                                            </Col>
                                        </Row>
                                    </Col>

                                </Row>

                                <Col md={24} xs={24} style={{textAlign: "center", marginTop: "10px"}}>
                                    <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
                                        <div className="sDash_form-action">
                                            <button
                                                type="button"
                                                className="btn btn-danger"
                                                onClick={() => history.goBack()}
                                            >
                                                Back
                                            </button>
                                        </div>
                                        {(state.status === 0 || state.status === 2) && <>
                                            <div className="sDash_form-action">
                                                <button
                                                    className="btn btn-success submit-button"
                                                    size="large"
                                                    onClick={() => handleApprove(1)}
                                                >
                                                    Approve
                                                </button>
                                            </div>
                                        </>}
                                    </div>
                                </Col>
                            </Card>
                        </Col>
                    </div>
                </Row>
            </Main>
        </>);
};

export default DepositDetails;
