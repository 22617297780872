import React, {useState, useEffect} from 'react';
import {Main} from "../styled";
import {PageHeader} from "../../components/page-headers/page-headers";
import {Card, Col, DatePicker, Form, Row, Table} from "antd";
import FontAwesome from "react-fontawesome";
import * as PropTypes from "prop-types";
import axios from "axios";
import "../customStyle.css";
import Helper from "../../helpers/Helper";
import {Link, useHistory} from "react-router-dom";
import Loader from "../../components/loader";
import {ApiUrl} from "../ApiUrl";
import ChangeMarketValue from "./ChangeMarketValue";

FontAwesome.propTypes = {
    icon: PropTypes.string, size: PropTypes.number
};

function MarketValueHistory() {

    const [marketValue, setMarketValue] = useState([]);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const history = useHistory();
    const [state, setState] = useState({
        url: ApiUrl.CHANGE_MARKET_VALUE
    });

    useEffect(() => {
        Helper.Title("Market Value Change History");
    }, []);

    const getMarketValue = () => {
        axios.get("changeMarketValue")
            .then((res) => {
                console.log(res.data);
                if (res.data.status === 1) {
                    setMarketValue(res.data.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        setLoading(true)
        console.log(state.url)
        axios.get(state.url)
            .then((response) => {
                console.log(response.data);
                if (response.data.status === 1) {
                    setMarketValue(response.data.data)
                }
                setLoading(false)
            })
            .catch((error) => {
                console.log(error);
            });
    }


    useEffect(() => {
        getMarketValue();
    }, [state.url]);


    const onSearch = async (values) => {
        let qs = Helper.GetQueryStrings(values)

        history.push("?" + qs);
        const newURL = state.url + "?" + qs;
        console.log(newURL);
        setState({url: newURL});
    }

    const columns = [
        {
            title: 'SL No.',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Customer',
            dataIndex: 'investor_name',
            key: 'investor_name'
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date'
        },
        {
            title: 'Previous Value',
            dataIndex: 'last_value',
            key: 'last_value'
        },
        {
            title: 'New Value',
            dataIndex: 'new_value',
            key: 'new_value'
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'created_at'
        }
    ]

    const data = []

    marketValue.map((log, index) => {
        data.push({
            id: ++index,
            investor_name: log.investor_name,
            date: Helper.DateFormat(log.date),
            last_value: Helper.CurrencyFormat(log.last_value),
            new_value: Helper.CurrencyFormat(log.new_value),
            created_at: Helper.CreatedDateFormat(log.created_at),
        })
    })
    const [visible, setVisible] = useState(false);
    const handleCallback = (childData) => {
        setVisible(childData)
        getMarketValue()
    }
    const showChangeMarketValue = () => {
        setVisible(true)
    }

    return (<>
            {loading ? <Loader/> : ""}
            <PageHeader
                ghost
                title="Market Value Change History"
            />
            <Main>
                <Row>
                    <Col lg={24} md={24} sm={24} xs={24} style={{margin: "0px 0 15px 0"}}>
                        <Card title="Advance Search" style={{padding: "10px"}}>
                            <Form form={form} name="input-form" layout="vertical" onFinish={onSearch}>
                                <Row gutter={24}>
                                    <Col lg={6} md={6} xs={24}>
                                        <Form.Item
                                            name="from_date"
                                            label="From Date"
                                        >
                                            <DatePicker className="gx-w-100" format="MM-DD-YYYY"
                                                        placeholder="Select Date"/>
                                        </Form.Item>
                                    </Col>

                                    <Col lg={6} md={6} xs={24}>
                                        <Form.Item
                                            name="to_date"
                                            label="To Date"
                                        >
                                            <DatePicker className="gx-w-100" format="MM-DD-YYYY"
                                                        placeholder="Select Date"/>
                                        </Form.Item>
                                    </Col>
                                    <Col lg={2} md={2} xs={2} style={{marginTop: "24px"}}>
                                        <div className="sDash_form-action">
                                            <button type="submit" className="btn btn-success" size="large" id="submit">
                                                Submit
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>
                    </Col>

                    <Col lg={24} md={24} sm={24} xs={24}>
                        <Card title="Market Value Change History"
                              extra={<Link to={"#"} className="btn-add" onClick={showChangeMarketValue}><i
                                  className="fa fa-plus"/>
                              </Link>}>
                            <Table
                                columns={columns}
                                dataSource={data}
                                bordered
                                pagination={{position: ['bottomCenter']}}
                                scroll={{x: true}}
                            />
                        </Card>
                    </Col>
                    <ChangeMarketValue
                        visible={visible}
                        isShow={handleCallback}
                    />
                </Row>
            </Main>
        </>);
}

export default MarketValueHistory;
