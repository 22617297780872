import actions from './actions';

const { LOADER, GET_ACCOUNT_DETAILS, UPDATE_SUSU_ACCOUNT } = actions;

const initState = {
  loading: false,
  data: {},
};

const AdminSuSuAccountReducer = (state = initState, action) => {
  const { type, data, err } = action;
  
  switch (type) {
    
    case GET_ACCOUNT_DETAILS:
      return {
        ...state,
        data: data,
      };
    case UPDATE_SUSU_ACCOUNT:
      return {
        ...state,
        loading: true,
      };
      
    case LOADER:
      return {
        ...state,
        loading: action.payload
      };
    // case UPDATE_PROFILE_LOADER_FALSE:
    //   return {
    //     ...state,
    //     updateprofileloader: false
    //   }
    default:
      return state;
  }
};
export default AdminSuSuAccountReducer;
