import React, {useState, useEffect} from 'react';
import { Menu } from 'antd';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import FeatherIcon from 'feather-icons-react';
import FontAwesome from 'react-fontawesome';
import propTypes from 'prop-types';
import axios from "axios";
import Cookies from "js-cookie";
import {login, logOut} from "../redux/authentication/actionCreator";
import LocalStorageData from "../helpers/LocalStorageData";
import {useDispatch} from "react-redux";

const { SubMenu } = Menu;

const MenuItems = ({ props, darkMode, toggleCollapsed, topMenu, events }) => {
    const { path } = useRouteMatch();

    const [state, setState] = useState({
        checked: null,
        userid: "",
        password: "",
        msg: "",
        isLoading: false,
        redirect: false,
        errMsgEmail: "",
        errMsgPwd: "",
        errMsg: "",
    });

    const[userMenu, setUserMenu] = useState([]);

    const pathName = window.location.pathname;
    const pathArray = pathName.split(path);
    const mainPath = pathArray[1];
    const mainPathSplit = mainPath.split('/');

    const { onRtlChange, onLtrChange, modeChangeDark, modeChangeLight, modeChangeTopNav, modeChangeSideNav } = events;
    const [openKeys, setOpenKeys] = React.useState(
        !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
    );

    const onOpenChange = keys => {
        console.log(openKeys);
        for(let i=0;i<=1000;i++) {
            setOpenKeys(keys[keys.length - 1] === i ? [keys.length && keys[keys.length - 1]] : keys);

        }
    };

    const onClick = item => {
        if (item.keyPath.length === 1) setOpenKeys([]);
    };


    let errorMsg = "";
    let UserMenu = [];

    useEffect(() => {
        // console.log("Hello");
        getUserMenu();
        console.log(topMenu);

    }, []);

    const getUserMenu = async () => {
        const userData = localStorage.getItem("userData");
        const dataparse = JSON.parse(userData);
        const userId = dataparse.user_name || "";
        //console.log(Password);
        //dispatch(login(userId,Password));
        //history.push('/admin');

        return await axios.get("getusermenu/?username="+userId)
            .then((response) => {
                // console.log(response);
                if(response.status == 200) {
                    setUserMenu(response.data.allmenu);
                    // console.log(response.data.allmenu);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const dispatch = useDispatch();
    const SignOut = e => {
        e.preventDefault();
        localStorage.clear()
        dispatch(logOut());
    };

    if(parseInt(LocalStorageData.AppStorage('area')) === 1 ) {
        return (
            <Menu
                onOpenChange={onOpenChange}
                onClick={onClick}
                mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
                theme={darkMode && 'dark'}
                // // eslint-disable-next-line no-nested-ternary
                defaultSelectedKeys={
                    !topMenu
                        ? [
                            `${
                                mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
                            }`,
                        ]
                        : []
                }
                defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
                overflowedIndicator={<FeatherIcon icon="more-vertical"/>}
                openKeys={openKeys}
            >


                <Menu.Item
                    icon={
                        !topMenu && (
                            <NavLink className="menuItem-iocn" to={`${path}`}>
                                <FeatherIcon icon="home"/>
                            </NavLink>
                        )
                    }
                    key="dashboard"
                >
                    <NavLink onClick={toggleCollapsed} to={`${path}`}>
                        Dashboard
                    </NavLink>
                </Menu.Item>

                <SubMenu key="developer" icon={!topMenu && <FeatherIcon icon="code"/>} title="Developer"
                         style={{display: "none"}}>
                    <Menu.Item key="module">
                        <NavLink onClick={toggleCollapsed} to={`${path}/developer/modules`}>
                            Modules
                        </NavLink>
                    </Menu.Item>
                    <Menu.Item key="submodule">
                        <NavLink onClick={toggleCollapsed} to={`${path}/developer/submodules`}>
                            Submodules
                        </NavLink>
                    </Menu.Item>
                    <Menu.Item key="menu">
                        <NavLink onClick={toggleCollapsed} to={`${path}/developer/menu`}>
                            Menu
                        </NavLink>
                    </Menu.Item>
                    <Menu.Item key="status">
                        <NavLink onClick={toggleCollapsed} to={`${path}/developer/status`}>
                            Status Lable
                        </NavLink>
                    </Menu.Item>
                    <Menu.Item key="export">
                        <NavLink onClick={toggleCollapsed} to={`${path}/developer/export`}>
                            Module Export
                        </NavLink>
                    </Menu.Item>
                    <Menu.Item key="import">
                        <NavLink onClick={toggleCollapsed} to={`${path}/developer/import`}>
                            Module Import
                        </NavLink>
                    </Menu.Item>
                </SubMenu>

                {
                    userMenu.map((module, index) =>
                        <SubMenu key={module.id} icon={!topMenu &&
                                <NavLink className="menuItem-icon" to={`${path}`}>
                                        {/*<FontAwesome name={module.name} className={module.icon} style={{*/}
                                        {/*    width: '0%',*/}
                                        {/*    marginLeft: '-16px',*/}
                                        {/*    color: '#ADB4D2',*/}
                                        {/*    margin: '0px 13px 0px -17px',*/}
                                        {/*    display: "block"*/}
                                        {/*}}/>*/}
                                        <FeatherIcon icon={module.icon} />
                                </NavLink>
                            } title={module.label}>

                            {
                                module.submodule.map((submenu, index) => {
                                    if (submenu.menu.length === 0) {
                                        return <Menu.Item key={submenu.id}
                                                          // icon={<FontAwesome className="fa fa-circle"/>}
                                                          // style={{paddingLeft: "15px"}}
                                                          style={{ marginLeft: "-35px"}}

                                        >
                                            <NavLink
                                                onClick={() => {
                                                    toggleCollapsed();
                                                    modeChangeLight();
                                                }}
                                                to={`${path}/` + module.urlkey + '/' + submenu.urlkey}
                                                // style={{marginLeft: "-180px"}}
                                            >
                                                {submenu.name}
                                            </NavLink>
                                        </Menu.Item>
                                    } else {
                                        return <SubMenu key={submenu.id} icon={!topMenu && <FeatherIcon icon="circle"/>}
                                            // <FontAwesome name={submenu.label}  style={{width:"0%",marginRight: "15px"}}/>

                                                        title={submenu.name} style={{ marginLeft: "20px"}}>
                                            {submenu.menu.map((menu, index) =>
                                                <Menu.Item key={menu.id} style={{ marginLeft: "-35px"}}>
                                                    <NavLink
                                                        onClick={() => {
                                                            toggleCollapsed();
                                                            modeChangeLight();
                                                        }}
                                                        to={`${path}/` + module.urlkey + '/' + menu.urlkey}
                                                    >
                                                        {menu.name}
                                                    </NavLink>
                                                </Menu.Item>
                                            )}
                                        </SubMenu>
                                    }
                                })
                            }
                        </SubMenu>
                    )
                }

                <Menu.Item
                    icon={
                        !topMenu && (
                            <NavLink className="menuItem-iocn" to="#" >
                                <FeatherIcon icon="log-out"/>
                            </NavLink>
                        )
                    }
                    key="support"
                >
                    <NavLink to="#" onClick={SignOut}>
                        Log Out
                    </NavLink>
                </Menu.Item>

            </Menu>
        )
    } else {

        return (
            <Menu
                onOpenChange={onOpenChange}
                onClick={onClick}
                mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
                theme={darkMode && 'dark'}
                // // eslint-disable-next-line no-nested-ternary
                defaultSelectedKeys={
                    !topMenu
                        ? [
                            `${
                                mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
                            }`,
                        ]
                        : []
                }
                defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
                overflowedIndicator={<FeatherIcon icon="more-vertical"/>}
                openKeys={openKeys}
            >


                <Menu.Item
                    icon={
                        !topMenu && (
                            <NavLink className="menuItem-iocn" to={`${path}`}>
                                <FeatherIcon icon="home"/>
                            </NavLink>
                        )
                    }
                    key="dashboard"
                >
                    <NavLink onClick={toggleCollapsed} to={`${path}`}>
                        Dashboard
                    </NavLink>
                </Menu.Item>

                <SubMenu key="susuaccount" icon={!topMenu && <FeatherIcon icon="layers"/>} title="SuSu Account">
                    <SubMenu key="account" icon={!topMenu && <FeatherIcon icon="circle"/>} title="Account" className="custom_submenu" style={{ marginLeft: "20px"}}>
                        <Menu.Item key="open account" className="custom_menuitem" style={{ marginLeft: "-35px"}}>
                            <NavLink onClick={toggleCollapsed} to={`${path}/account/openaccount`}>
                                Open Account
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item key="open account" className="custom_menuitem" style={{ marginLeft: "-35px"}}>
                            <NavLink onClick={toggleCollapsed} to={`${path}/account/manageaccount`}>
                                Manage Account
                            </NavLink>
                        </Menu.Item>
                    </SubMenu>

                    <SubMenu key="susudeposit" icon={!topMenu && <FeatherIcon icon="circle"/>} title="Contribution"  style={{ marginLeft: "20px"}}>
                        <Menu.Item key="addcontribution">
                            <NavLink onClick={toggleCollapsed} to={`${path}/account/addcontribution`} style={{ marginLeft: "-35px"}}>
                                Add Contribution
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item key="contributionlist">
                            <NavLink onClick={toggleCollapsed} to={`${path}/account/managecontribution`} style={{ marginLeft: "-35px"}}>
                                Contribution List
                            </NavLink>
                        </Menu.Item>
                    </SubMenu>

                    <SubMenu key="susuwithdraw" icon={!topMenu && <FeatherIcon icon="circle"/>} title="Withdrawal Request"  style={{ marginLeft: "20px"}}>
                        {/*<Menu.Item key="newwithdrawrequest">*/}
                            {/*<NavLink onClick={toggleCollapsed} to={`${path}/account/newwithdrawalrequest`} style={{ marginLeft: "-35px"}}>*/}
                            {/*    New Withdrawal Request*/}
                            {/*</NavLink>*/}
                        {/*</Menu.Item>*/}
                        <Menu.Item key="withdrawlist">
                            <NavLink onClick={toggleCollapsed} to={`${path}/account/withdrawallist`} style={{ marginLeft: "-35px"}}>
                                Withdrawal Lists
                            </NavLink>
                        </Menu.Item>
                    </SubMenu>
                </SubMenu>

                <SubMenu key="deposit" icon={!topMenu && <FeatherIcon icon="download-cloud"/>} title="Deposit">
                    <Menu.Item key="adddeposit">
                        <NavLink onClick={toggleCollapsed} to={`${path}/deposit/adddeposit`} style={{ marginLeft: "-35px"}}>
                            Add Deposit
                        </NavLink>
                    </Menu.Item>
                    <Menu.Item key="depositrequest">
                        <NavLink onClick={toggleCollapsed} to={`${path}/deposit/depositrequest`} style={{ marginLeft: "-35px"}}>
                            Deposit Requests
                        </NavLink>
                    </Menu.Item>
                    <Menu.Item key="depositreport">
                        <NavLink onClick={toggleCollapsed} to={`${path}/deposit/depositreport`} style={{ marginLeft: "-35px"}}>
                            Deposit Report
                        </NavLink>
                    </Menu.Item>
                </SubMenu>

                <SubMenu key="developer" icon={!topMenu && <FeatherIcon icon="upload-cloud"/>} title="Withdraw">
                    {/*<Menu.Item key="module">*/}
                    {/*    <NavLink onClick={toggleCollapsed} to={`${path}/withdraw/withdrawRequest`} style={{ marginLeft: "-35px"}}>*/}
                    {/*        Withdrawal Request*/}
                    {/*    </NavLink>*/}
                    {/*</Menu.Item>*/}
                    <Menu.Item key="submodule">
                        <NavLink onClick={toggleCollapsed} to={`${path}/withdraw/withdrawRequestHistory`} style={{ marginLeft: "-35px"}}>
                            Withdrawal History
                        </NavLink>
                    </Menu.Item>
                </SubMenu>

                <Menu.Item
                    icon={
                        !topMenu && (
                            <NavLink className="menuItem-iocn" to={`${path}/transactionhistory`}>
                                <FeatherIcon icon="trending-up"/>
                            </NavLink>
                        )
                    }
                    key="transaction"
                >
                    <NavLink onClick={toggleCollapsed} to={`${path}/transactionhistory`}>
                        My Transaction
                    </NavLink>
                </Menu.Item>

                <SubMenu key="payment" icon={!topMenu && <FeatherIcon icon="list"/>} title="Bank / Card Setup">
                    <Menu.Item key="add_acc" style={{ marginLeft: "-35px"}}>
                        <NavLink onClick={toggleCollapsed} to={`${path}/payment/add-account`}>
                            Add Account
                        </NavLink>
                    </Menu.Item>
                    <Menu.Item key="list_acc" style={{ marginLeft: "-35px"}}>
                        <NavLink onClick={toggleCollapsed} to={`${path}/payment/account-list`}>
                            Account Lists
                        </NavLink>
                    </Menu.Item>
                    <Menu.Item key="add_card" style={{ marginLeft: "-35px"}}>
                        <NavLink onClick={toggleCollapsed} to={`${path}/payment/add-card`}>
                            Add Card
                        </NavLink>
                    </Menu.Item>
                    <Menu.Item key="list_card" style={{ marginLeft: "-35px"}}>
                        <NavLink onClick={toggleCollapsed} to={`${path}/payment/card-list`}>
                            Card Lists
                        </NavLink>
                    </Menu.Item>
                </SubMenu>

                <SubMenu key="settings" icon={!topMenu && <FeatherIcon icon="settings"/>} title="Settings">
                    <Menu.Item key="prf_set" style={{ marginLeft: "-35px"}}>
                        <NavLink onClick={toggleCollapsed} to={`${path}/profile/profileSettings/profile`}>
                            Profile Setting
                        </NavLink>
                    </Menu.Item>
                    <Menu.Item key="pas_set" style={{ marginLeft: "-35px"}}>
                        <NavLink onClick={toggleCollapsed} to={`${path}/profile/profileSettings/password`}>
                            Change Password
                        </NavLink>
                    </Menu.Item>
                </SubMenu>

                <Menu.Item
                    icon={
                        !topMenu && (
                            <NavLink className="menuItem-iocn" to={`${path}/support/messagelist`}>
                                <FeatherIcon icon="help-circle"/>
                            </NavLink>
                        )
                    }
                    key="support"
                >
                    <NavLink onClick={toggleCollapsed} to={`${path}/support/messagelist`}>
                        Support
                    </NavLink>
                </Menu.Item>

                <Menu.Item
                    icon={
                        !topMenu && (
                            <NavLink className="menuItem-iocn" to={`${path}/agreement/myagreement`}>
                                <FeatherIcon icon="book"/>
                            </NavLink>
                        )
                    }
                    key="agreement"
                >
                    <NavLink onClick={toggleCollapsed} to={`${path}/agreement/myagreement`}>
                        My Agreement
                    </NavLink>
                </Menu.Item>

                <Menu.Item
                    icon={
                        !topMenu && (
                            <NavLink className="menuItem-iocn" to="#" >
                                <FeatherIcon icon="log-out"/>
                            </NavLink>
                        )
                    }
                    key="support"
                >
                    <NavLink to="#" onClick={SignOut}>
                        Log Out
                    </NavLink>
                </Menu.Item>
            </Menu>
        )
    }
};

MenuItems.propTypes = {
    darkMode: propTypes.bool,
    topMenu: propTypes.bool,
    toggleCollapsed: propTypes.func,
    events: propTypes.object,
};

export default MenuItems;
