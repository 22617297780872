import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const AddUser = lazy(() => import('../../container/user-management/users/CreateUser'));
const UserList = lazy(() => import('../../container/user-management/users/UserList'));
const EditUser = lazy(() => import('../../container/user-management/users/EditUser'));
const AddUserRole = lazy(() => import('../../container/user-management/roles/CreateRole'));
const UserRolesList = lazy(() => import('../../container/user-management/roles/RolesList'));
const UserActivity = lazy(() => import('../../container/user-management/user-activity/Activity'));
const LoginHistory = lazy(() => import('../../container/user-management/user-activity/LoginHistory'));

const PagesRoute = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
        <Route path={`${path}/newuser`} component={AddUser} />
        <Route path={`${path}/userlist`} component={UserList} />
        <Route path={`${path}/edituser/:id`} component={EditUser} />
        <Route path={`${path}/newuserroles`} component={AddUserRole} />
        <Route path={`${path}/userroles`} component={UserRolesList} />
        <Route path={`${path}/useractivity`} component={UserActivity} />
        <Route path={`${path}/loginhistory`} component={LoginHistory} />
    </Switch>
  );
};

export default PagesRoute;
