import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import MarketValueHistory from "../../container/investment/MarketValueHistory";

const AddAccount = lazy(() => import('../../container/customer_portal/payment_method/account/AddAccount'));
const AccountList = lazy(() => import('../../container/customer_portal/payment_method/account/AccountLists'));
const AccountEdit = lazy(() => import('../../container/customer_portal/payment_method/account/AccountEdit'));
const AddCard = lazy(() => import('../../container/customer_portal/payment_method/payment_card/AddCard'));
const CardList = lazy(() => import('../../container/customer_portal/payment_method/payment_card/CardLists'));
const CardEdit = lazy(() => import('../../container/customer_portal/payment_method/payment_card/CardEdit'));


const CustomerPayment = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={`${path}/add-account`} component={AddAccount} />
            <Route path={`${path}/account-list`} component={AccountList} />
            <Route path={`${path}/account-edit/:id`} component={AccountEdit} />
            <Route path={`${path}/add-card`} component={AddCard} />
            <Route path={`${path}/card-list`} component={CardList} />
            <Route path={`${path}/card-edit/:id`} component={CardEdit} />
        </Switch>
    );
};

export default CustomerPayment;
