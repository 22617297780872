import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const NewInvestment = lazy(() => import('../../container/investment/NewInvestment'));
const InvestmentLevelList = lazy(() => import('../../container/investment/investment_level/InvestmentLevelList'));
const InvestmentLevelCreate = lazy(() => import('../../container/investment/investment_level/CreateLevel'));
const InvestmentLevelEdit = lazy(() => import('../../container/investment/investment_level/EditLevel'));
const InvestmentReport = lazy(() => import('../../container/investment/InvestmentReport'));
const EditInvestment = lazy(() => import('../../container/investment/EditInvestment'));
const ChangeMarketValue = lazy(() => import('../../container/investment/ChangeMarketValue'));
const MarketValueChangeHistory = lazy(() => import('../../container/investment/MarketValueHistory'));

const Investment = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={`${path}/newInvestment`} component={NewInvestment} />
            <Route path={`${path}/investmentLevel`} component={InvestmentLevelList} />
            <Route path={`${path}/createinvestmentLevel`} component={InvestmentLevelCreate} />
            <Route path={`${path}/editinvestmentLevel/:id`} component={InvestmentLevelEdit} />
            <Route path={`${path}/investmentReport`} component={InvestmentReport} />
            <Route path={`${path}/editinvestment/:id`} component={EditInvestment} />
            <Route path={`${path}/changeMarketValue`} component={ChangeMarketValue} />
            <Route path={`${path}/marketValueHistory`} component={MarketValueChangeHistory} />
        </Switch>
    );
};

export default Investment;
