import React, {useEffect, useState} from "react";
import {Card, Col, DatePicker, Form, Input, Row} from "antd";
import {Main} from "../styled";
import {PageHeader} from "../../components/page-headers/page-headers";
import {useHistory} from "react-router-dom";
import axios from "axios";
import "../customStyle.css";
import Helper from "../../helpers/Helper";
import {ApiUrl} from "../ApiUrl";
import CISUI from "../../helpers/CISUI";
import Loader from "../../components/loader";

let redirectTo = "/admin/deposit/depositReport";

const AddDeposit = () => {
    const history = useHistory();

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({});

    const [customers, setCustomers] = useState([]);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        getCustomer();
        Helper.Title("Add Deposit");
    }, []);

    const getCustomer = () => {
        axios
            .get(ApiUrl.CUSTOMER_LIST)
            .then((res) => {
                console.log(res.data);
                if (res.data.status === 1) {
                    setCustomers(res.data.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleChange = (e) => {
        setState({
            ...state, [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (values) => {
        let data = {
            ...values,
        };
        setLoading(true);
        axios
            .post(ApiUrl.ADDD_DEPOSITE, data)
            .then((res) => {
                if (res.data.status === 1) {
                    history.push(redirectTo);
                    CISUI().Notification("success", res.data.msg);
                } else {
                    CISUI().Notification("warning", res.data.msg);
                }
                setLoading(false);
            })
            .catch((errors) => console.log(errors));
    };
    const FindCustomerAccount = (e) => {
        const customerID = e.target.value;
        setState({
            ...state, [e.target.name]: customerID,
        });

        if (customerID.length >= 5) {
            document.getElementById("cshint").innerText = "Please wait..";
            document.getElementById("cshint").style.color = "red";
            axios
                .get("admin/customerinfo/" + customerID)
                .then((res) => {
                    if (res.data.status === 1) {
                        const data = res.data.data;
                        document.getElementById("customer_name").value = data.name;
                        document.getElementById("cshint").innerText = "Account verified";
                        document.getElementById("cshint").style.color = "green";
                    } else {
                        document.getElementById("cshint").innerText = res.data.msg;
                        document.getElementById("cshint").style.color = "red";
                        document.getElementById("customer_name").value = "";
                    }
                })
                .catch((errors) => {
                });
        } else {
            document.getElementById("cshint").innerText = "Please enter 5 digit account number";
            document.getElementById("cshint").style.color = "red";
            document.getElementById("customer_name").value = "";
        }
    };

    if(loading){
        return <Loader />;
    }

    return (<>
        <PageHeader
            ghost
            title="Add Deposit"
        />

        <Main>
            <Row gutter={25}>
                <Col xs={24}>
                    <Card title="Add Deposit" extra={CISUI().ListButton(redirectTo)}>
                        <Form
                            form={form}
                            name="input-form"
                            layout="vertical"
                            onFinish={handleSubmit}
                        >
                            <Row gutter={30}>
                                <Col md={12} xs={24}>
                                    <Form.Item
                                        name="date"
                                        label="Date"
                                        rules={[{
                                            required: true, message: "Date is required",
                                        },]}
                                    >
                                        <DatePicker style={{width: "100%"}}/>
                                    </Form.Item>
                                </Col>
                                <Col md={12} xs={24}>
                                    <Form.Item
                                        name="description"
                                        label="Description"
                                        rules={[{
                                            required: true, message: "Description is required",
                                        },]}
                                    >
                                        <Input placeholder="Description"/>
                                    </Form.Item>
                                </Col>
                                <Col md={12} xs={24}>
                                    <Form.Item
                                        name="customer_account"
                                        label="Customer Account Number"
                                        onChange={FindCustomerAccount}
                                        rules={[{
                                            required: true, message: "Account Number is required",
                                        },]}
                                    >
                                        <Input
                                            type="text"
                                            placeholder="Enter Customer Account Number"
                                        />
                                    </Form.Item>
                                    <div
                                        id="cshint"
                                        style={{color: "red", fontSize: "10px"}}
                                    ></div>
                                </Col>
                                <Col md={12} xs={24}>
                                    <Form.Item
                                        name="customer_name"
                                        label="Customer Name"
                                    >
                                        <Input
                                            type="text"
                                            readOnly={true}
                                            id="customer_name"
                                            disabled
                                        />
                                    </Form.Item>
                                </Col>
                                <Row></Row>
                                <Col md={12} xs={24}>
                                    <Form.Item
                                        name="amount"
                                        label="Amount"
                                        rules={[{
                                            required: true, message: "Amount is required",
                                        },]}
                                    >
                                        <Input type="number" placeholder="Amount"/>
                                    </Form.Item>
                                </Col>
                                <Col md={12} xs={24}>
                                    <Form.Item
                                        name="remark"
                                        label="Remark"
                                        rules={[{
                                            required: false, message: "Remark is required",
                                        },]}
                                    >
                                        <Input placeholder="Remark"/>
                                    </Form.Item>
                                </Col>
                                <Col md={24} xs={24} style={{textAlign: "center"}}>
                                    <div
                                        style={{
                                            width: "100%", display: "flex", justifyContent: "center",
                                        }}
                                    >
                                        <div className="sDash_form-action">
                                            <button
                                                type="button"
                                                className="btn btn-danger"
                                                onClick={() => history.goBack()}
                                            >
                                                Back
                                            </button>
                                        </div>
                                        <div className="sDash_form-action">
                                            <button
                                                type="button"
                                                className="btn btn-danger"
                                                onClick={() => form.resetFields()}
                                            >
                                                Reset
                                            </button>
                                        </div>
                                        <div className="sDash_form-action">
                                            <button
                                                type="submit"
                                                className="btn btn-success submit-button"
                                                size="large"
                                                id="submit"
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </Main>
    </>);
};

export default AddDeposit;
