import React, {useState} from 'react';
import {Row, Col, Form, Input, Select, notification} from 'antd';
import { FormValidationWrap, VerticalFormStyleWrap } from '../overview/Style';
import {CardToolbox, Main} from '../styled';
import {Cards} from "../../components/cards/frame/cards-frame";
import { Button } from '../../components/buttons/buttons';
import { PageHeader } from '../../components/page-headers/page-headers';
import {Link, useHistory} from "react-router-dom";
import FontAwesome from "react-fontawesome";
import axios from "axios";
import "../customStyle.css";

const { Option } = Select;
const { TextArea } = Input;

const CreateDepositType = () => {

    const history = useHistory()

    const [form] = Form.useForm();

    const [state, setState] = useState({
        name: "",
        description: "",
        status: "",
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {

        setState({
            ...state,
            [e.target.name]: e.target.value
        });
    }


    const handleSubmit = (e) => {
        e.preventDefault();

        axios.post("deposittype/add", state)
            .then(res => {
                if(res.data.status === 1) {
                    history.push('/admin/deposit/alldeposittypes');
                    notification["success"]({
                        message: 'Success.',
                        description: res.data.msg,
                    });
                } else {
                    notification["warning"]({
                        message: 'Error.',
                        description: res.data.msg,
                    });
                }
            })
            .catch(errors => {
                setErrors(errors.response.data.errors)
            })
    };


    return (
        <>
            <PageHeader
                ghost
                title="Add Deposit Type"
                buttons={[
                    <Button className="btn-add_new" size="default" type="info" key="1">
                        <Link to="/admin/deposit/alldeposittypes">
                            <FontAwesome  className={`fa fa-list`} style={{marginRight: '5px'}}/> Lists
                        </Link>
                    </Button>,
                ]}
            />

            <Main>
                <Row gutter={25}>
                    <Col xs={24}>
                        <FormValidationWrap>
                            <VerticalFormStyleWrap>
                                <Cards title="Add Deposit Type">
                                    <form name="sDash_validation-form" layout="vertical" id="form" onSubmit={handleSubmit}>
                                        <Row gutter={30}>
                                            <Col md={12} xs={24}>
                                                <div className="form-group">
                                                    <label htmlFor="name">Name <sup className="text-danger">*</sup></label>
                                                    <input type="text" className="form-control" name="name" id="name" onChange={handleChange} value={state.name} placeholder="Name" />
                                                    { errors && (
                                                        <p className={`text-danger`}>{errors.name}</p>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col md={12} xs={24}>
                                                <div className="form-group">
                                                    <label htmlFor="description">Description</label>
                                                    <textarea className="form-control" name="description" onChange={handleChange} value={state.description} placeholder="Details" />
                                                </div>
                                            </Col>
                                            <Col md={12} xs={24}>
                                                <div className="form-group">
                                                    <label htmlFor="status">Status <sup className="text-danger">*</sup></label>
                                                    <select size="large" className="form-control" name="status" value={state.status} onChange={handleChange} >
                                                        <option value="">Select An Option</option>
                                                        <option value="1">Active</option>
                                                        <option value="0">Inactive</option>
                                                    </select>
                                                    { errors && (
                                                        <p className={`text-danger`}>{errors.status}</p>
                                                    )}
                                                </div>
                                            </Col>

                                        </Row>

                                        <Row className={`mt-20`}>
                                            <div className="sDash_form-action" style={{ marginRight: '10px' }}>
                                                <Button type="danger" size="large"
                                                        onClick={() => {
                                                            return document.getElementById("form").reset();
                                                        }}
                                                >
                                                    Reset
                                                </Button>
                                            </div>
                                            <div className="sDash_form-action">
                                                <Button htmlType="submit" type="success" size="large">
                                                    Submit
                                                </Button>
                                            </div>
                                        </Row>
                                    </form>
                                </Cards>
                            </VerticalFormStyleWrap>
                        </FormValidationWrap>
                    </Col>
                </Row>
            </Main>
        </>
    );
};

export default CreateDepositType;
