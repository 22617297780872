import React, { lazy } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

const TypeList = lazy(() => import('../../container/customer_type/List'));
const TypeCreate = lazy(() => import('../../container/customer_type/CreateTypes'));
const TypeEdit = lazy(() => import('../../container/customer_type/EditTypes'));
const CustomerList = lazy(() => import('../../container/customer/CustomerList'));
const Agreement = lazy(() => import('../../container/customer/Agreement'));
const CustomerCreate = lazy(() => import('../../container/customer/CreateCustomer'));
const CustomerEdit = lazy(() => import('../../container/customer/EditCustomer'));
const PendingCustomerLists = lazy(() => import('../../container/customer/PendingCustomerLists'));

const CustomerRoute = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={`${path}/allcustomertype`} component={TypeList} />
            <Route path={`${path}/newcustomertype`} component={TypeCreate} />
            <Route path={`${path}/customertypeedit/:id`} component={TypeEdit} />
            <Route path={`${path}/agreement/:id`} component={Agreement} />
            <Route path={`${path}/allcustomer`} component={CustomerList} />
            <Route path={`${path}/newcustomer`} component={CustomerCreate} />
            <Route path={`${path}/editcustomer/:id`} component={CustomerEdit} />
            <Route path={`${path}/pendingCustomerLists`} component={PendingCustomerLists} />
        </Switch>
    );
};

export default CustomerRoute;
