import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const TransactionReport = lazy(() => import('../../container/reports/TransactionReport'));
const CurrentMarketValueReport = lazy(() => import('../../container/reports/CurrentMarketValueReport'));

const Report = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={`${path}/transactionReport`} component={TransactionReport} />
            <Route path={`${path}/currentMarketValue`} component={CurrentMarketValueReport} />
        </Switch>
    );
};

export default Report;
