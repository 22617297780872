const actions = {
  GET_CUSTOMER_RUNNING_CONTRIBUTION: 'GET_CUSTOMER_RUNNING_CONTRIBUTION',

  getCustomerRunningContribution: (data) => {
    // console.log(data)
    return {
      type: actions.GET_CUSTOMER_RUNNING_CONTRIBUTION,
      data,
    };
  }
};

export default actions;
