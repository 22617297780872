import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const AddDeposit = lazy(() => import('../../container/customer_portal/customer_deposit/AddDeposit'));
const DepositDetails = lazy(() => import('../../container/customer_portal/customer_deposit/DepositDetails'));
const DepositReport = lazy(() => import('../../container/customer_portal/customer_deposit/DepositReport'));
const DepositRequest = lazy(() => import('../../container/customer_portal/customer_deposit/DepositRequest'));

const CustomerPayment = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={`${path}/adddeposit`} component={AddDeposit} />
            <Route path={`${path}/depositreport`} component={DepositReport} />
            <Route path={`${path}/depositrequest`} component={DepositRequest} />
            <Route path={`${path}/depositdetails/:id`} component={DepositDetails} />
        </Switch>
    );
};

export default CustomerPayment;
