import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const ModuleList = lazy(() => import('../../container/developer-management/modules/ModuleList'));
const ModuleCreate = lazy(() => import('../../container/developer-management/modules/CreateModule'));
const SubModuleList = lazy(() => import('../../container/developer-management/sub-modules/SubModuleList'));
const SubModuleCreate = lazy(() => import('../../container/developer-management/sub-modules/CreateSubModule'));
const MenuList = lazy(() => import('../../container/developer-management/menu/MenuList'));
const MenuCreate = lazy(() => import('../../container/developer-management/menu/CreateMenu'));
const StatusList = lazy(() => import('../../container/developer-management/status-label/StatusList'));
const StatusCreate = lazy(() => import('../../container/developer-management/status-label/CreateStatus'));
const Export = lazy(() => import('../../container/developer-management/module-export/ModuleExport'));
const Import = lazy(() => import('../../container/developer-management/module-import/ModuleImport'));

const Developer = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={`${path}/modules`} component={ModuleList} />
            <Route path={`${path}/create-modules`} component={ModuleCreate} />
            <Route path={`${path}/submodules`} component={SubModuleList} />
            <Route path={`${path}/create-submodules`} component={SubModuleCreate} />
            <Route path={`${path}/menu`} component={MenuList} />
            <Route path={`${path}/create-menu`} component={MenuCreate} />
            <Route path={`${path}/status`} component={StatusList} />
            <Route path={`${path}/create-status`} component={StatusCreate} />
            <Route path={`${path}/export`} component={Export} />
            <Route path={`${path}/import`} component={Import} />
        </Switch>
    );
};

export default Developer;
