import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import MarketValueHistory from "../../container/investment/MarketValueHistory";

const WithdrawRequest = lazy(() => import('../../container/customer_portal/withdraw/WithdrawRequest'));
const WithdrawRequestHistory = lazy(() => import('../../container/customer_portal/withdraw/WithdrawRequestHistory'));
const WithdrawRequestEdit = lazy(() => import('../../container/customer_portal/withdraw/EditWithdrawRequest'));

const CustomerWithdraw = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={`${path}/withdrawRequest`} component={WithdrawRequest} />
            <Route path={`${path}/withdrawRequestHistory`} component={WithdrawRequestHistory} />
            <Route path={`${path}/withdrawRequestEdit/:id`} component={WithdrawRequestEdit} />
        </Switch>
    );
};

export default CustomerWithdraw;
