import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const MyAgreement = lazy(() => import('../../container/customer_portal/agreement/MyAgreement'));

const CustomerAgreement = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}/myagreement`} component={MyAgreement} />
    </Switch>
  );
};

export default CustomerAgreement;
