
export const ApiUrl = {
	// Customer API
	// NEW_CUSTOMER: 'customer/newaccount',
	NEW_CUSTOMER: 'customer/add',
	CUSTOMER_LIST: 'customer',
	SHOW_CUSTOMER: 'customer/accountdetails',
	UPDATE_CUSTOMER: 'customer/accountupdate',
	DELETE_CUSTOMER: 'customer/accountdelete',
	DELETE_CUSTOMER_ACCOUNT: 'customer/delete',
	CUSTOMER_TYPE: 'customertype',
	DELETE_CUSTOMER_TYPE:'customertype/delete',
    // SuSu Account
	NEW_ACCOUNT: 'susuaccount/newaccount',
    SHOW_ACCOUNT: 'susuaccount/accountdetails',
	// NEW_CUSTOMER1:'customer/add',
	PENDING_CUSTOMER_LIST:'pendingCustomer',
	NEW_CUSTOMER_TYPE:'customertype/add',
	//group API
	NEW_GROUP: 'group/newgroup',
	GROUP_LIST:'group/groups',
	DELETE_GROUP: 'group/groupdelete',
	//  Contributions API
	NEW_CONTRIBUTION: 'contribution/addcontribution',
	CONTRIBUTION_LIST: 'contribution/contributions',
	CONTRIBUTION_DETAILS: 'contribution/contributiondetails/',
	CONTRIBUTION_APPROVE: 'contribution/contributionapprove/',

	// SuSu WithdrawRequest
	SUSU_WITHDRAW_LIST: 'withdrawal/withdrawrequests',
	SUSU_WITHDRAW_DELETE: 'withdrawal/deletewithdrawrequest',
	SUSU_WITHDRAW_ACCEPT: 'withdrawal/accept/',
	SUSU_WITHDRAW_CANCEL: 'withdrawal/cancel/',
	//investment level api
	INVESTMENT_REPORT:'investment',
	INVESTMENT_LEVEL_LIST:'/investmenttype',
	UPDATE_LEVEL:'investment/updateinvestmentLevel',
	DELETE_LEVEL:'',
	CHANGE_MARKET_VALUE:'changeMarketValue',
	ADD_CHANGE_MARKET_VALUE:'changeMarketValue/add',
	NEW_INVESTMENT:'investment/add',
	CREATE_LEVEL:'investmenttype/add',
	//Deposit
	DEPOSIT_LIST:'deposit',
	DEPOSIT_PENDING_REQUEST:'deposit/pendinglist',
	ADD_DEPOSIT: 'deposit/requestadd',
	DELETE_DEPOSITE:'deposit/delete',
	ADDD_DEPOSITE:'deposit/add',
	DEPOSIT_DETAILS:'deposit/details/',
	DEPOSIT_APPROVED:'deposit/approve/',

	// Withdraw
	ADD_WITHDRAW:'withdrawRequest/add',
	WITHDRAW_REPORT:'withdrawRequest/report',
	WITHDRAW_HISTORY: 'withdrawreporthistory',

	//Account List
	PENDING_ACCOUNT_LIST:'susuaccount/pendingaccounts',
	DELETE_ACCOUNT:'susuaccount/accountdelete',
	PENDING_ACCOUNT_STATUS: 'susuaccount/accountstatus/',
	//Card List
	CARD_LIST:'admin/cardlist',
	DELETE_CARD:'admin/deletecard',
	//Transaction Report
	TRANSACTION_REPORT:'transactionReport',
	CURRENT_MARKET_VALUE_REPORT:'changeMarketValueReport',
	//message
	MESSAGE_LIST:'admin/allmessage',
	MESSAGE_DETAILS: 'support/messagedetails',
	//update
	//update
	//Payment
	PAY_ACCOUNT_LIST:'accountlist',
	PAY_ADD_ACCOUNT:'addaccount',
    PAY_DELETE_ACCOUNT: 'accountdelete',


}