import React from 'react';
import {Button, notification, Tag} from "antd";
import {Link} from "react-router-dom";
import moment from "moment/moment";

const CISUI = () => {
    const AddButton = (url) => {
        return <Link to={url} className="btn-add"><i className="fa fa-plus" /></Link>
    }

    const ListButton = (url) => {
        return <Link to={url} className="btn-list"><i className="fa fa-list" /></Link>
    }

    const CurrencyFormat = (x) => {
        let number = parseFloat(x).toFixed(2);
        return "$" + number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const GetCurrencyFormat = (amount) => {
        // return <div style={{textAlign: 'right', marginRight: "30px"}}>{CurrencyFormat(amount || 0)}</div>
        return <div style={{textAlign: 'right', fontWeight: "550"}}>{CurrencyFormat(amount || 0)}</div>
    }

    const DateFormat = (data) => {
        return moment(data).format("MM/DD/YYYY");
    }

    const DateTimeFormat = (data) => {
        return moment(data).format("MM/DD/YYYY hh:mm:ss");
    }

    const WithdrawalStatusButton = (data) => {
        let html = "";
        if (data === 0) {
            // html =  <button className="btn btn-pending btn-sm customStatus">Pending</button>;
            html =  <Tag className="status-pending">Pending</Tag>;
        } else if(data === 1) {
            html = <Tag className="status-active">Accepted</Tag>;
        }
        else if(data === 2){
            html = <Tag className="status-runing">Paid</Tag>;
        }
        else if(data === 3){
            html =  <Tag className="status-declined">Declined</Tag>;
        }

        return html;
    }

    const StatusButton = (data) => {
        let html = "";
        if (data === 0) {
            // html =  <button className="btn btn-pending btn-sm customStatus">Pending</button>;
            html =  <Tag className="status-pending">Pending</Tag>;
        } else if(data === 1) {
            html = <Tag className="status-active">Active</Tag>;
        }
        else if(data === 2){
            html = <Tag className="status-runing">Running</Tag>;
        }
        else if(data === 3){
            html =  <Tag className="status-declined">Declined</Tag>;
        }

        return html;
    }

    const AgreementSigned = (data) => {
        let html = "";
        if (data === 1) {
            // html =  <button className="btn btn-pending btn-sm customStatus">Pending</button>;
            html =  <Tag className="status-active">Yes</Tag>;
        } else {
            html = <Tag className="status-pending">No</Tag>;
        }


        return html;
    }


    const PaymentStatusButton = (data) => {
        let html = "";
        if (data === 0) {
            // html =  <button className="btn btn-pending btn-sm customStatus">Pending</button>;
            html =  <Tag className="status-pending">Pending</Tag>;
        } else if(data === 1) {
            html = <Tag className="status-active">Success</Tag>;
        }
        else if(data === 2){
            html =  <Tag className="status-declined">Declined</Tag>;
        }

        return html;
    }

    const PaymentStatusButtonV2 = (data) => {
        let html = "";
        if (data === 0) {
            // html =  <button className="btn btn-pending btn-sm customStatus">Pending</button>;
            html =  <Tag className="status-declined">Failed</Tag>;
        } else if(data === 1) {
            html = <Tag className="status-active">Success</Tag>;
        }
        else if(data === 2){
            html =  <Tag className="status-runing">Processing</Tag>;
        }
        else if(data === 3){
            html =  <Tag className="status-declined">Requires Payment Method</Tag>;
        }

        return html;
    }

    const ContributionStatusButton = (data) => {
        let html = "";
        if (data === 0) {
            // html =  <button className="btn btn-pending btn-sm customStatus">Pending</button>;
            html =  <Tag className="status-declined">Pending</Tag>;
        } else if(data === 1) {
            html = <Tag className="status-active">Approved</Tag>;
        }
        else if(data === 2){
            html =  <Tag className="status-runing">Processing</Tag>;
        }
        else if(data === 3){
            html =  <Tag className="status-declined">Declined</Tag>;
        }

        return html;
    }

    const MessageStatusButton = (data) => {
        let html = "";
        if (data === 0) {
            html =  <Tag className="status-pending">Pending</Tag>;
        } else if(data === 1) {
            html = <Tag className="status-active">Solved</Tag>;
        }
        else if(data === 2){
            html = <Tag className="status-declined">Cancelled</Tag>;
        }

        return html;
    }

    const Notification = (type, msg) => {
        if(type === 'success'){
            notification["success"]({
                message: 'Success.',
                description: msg,
            });
        }
        else if(type === 'warning'){
            notification["warning"]({
                message: 'Warning.',
                description: msg,
            });
        }
        else if(type === 'info'){
            notification["info"]({
                message: 'Info.',
                description: msg,
            });
        }
        else if(type === 'error'){
            notification["Error"]({
                message: 'Error.',
                description: msg,
            });
        }
    }

    const AccountType = (type) => {
        let account_type = "";
        if(type === 1){
            account_type = "Individual";
        }
        else{
            account_type = "Group";
        }

        return account_type;
    }

    return{AddButton, ListButton, StatusButton, WithdrawalStatusButton, MessageStatusButton, PaymentStatusButton,
        GetCurrencyFormat, DateFormat, DateTimeFormat, Notification, AccountType, PaymentStatusButtonV2,
        ContributionStatusButton, AgreementSigned}

}

export default CISUI;

