const actions = {
  LOADER: 'LOADER',
  GET_ACCOUNT_DETAILS: 'GET_ACCOUNT_DETAILS',
  UPDATE_SUSU_ACCOUNT: 'UPDATE_SUSU_ACCOUNT',
  

  getAccountDetails: data => {
    return {
      type: actions.GET_ACCOUNT_DETAILS,
      data,
    };
  },

  updateSuSuAccount: data => {
    return {
      type: actions.UPDATE_SUSU_ACCOUNT,
      data,
    };
  },
  
  loaderStatus: data => {
    return {
      type: actions.LOADER,
      payload: data
    };
  },
  // updateProfileLoaderFalse: data => {
  //   return {
  //     type: actions.UPDATE_PROFILE_LOADER_FALSE
  //   };
  // }
};

export default actions;
