import React from 'react';
// import loader from "./gti_logo.png";
import loader from '../../static/img/loader.gif';
import './style.css';

const Loader = () => {

    return (
        <div className="loader">
            <div className="center-loading">
                <img src={loader} alt="" style={{ background: "transparent", width: "150px", height: "150px"}}/>
            </div>
        </div>
    );
};

export default Loader;
