import actions from './actions';

const { AUTH_USER } = actions;

const initState = {
  item: {}
};

/**
 *
 * @todo impure state mutation/explaination
 */
const AuthUserReducer = (state = initState, action) => {
  // const { type } = action;
  switch (action.type) {
    case AUTH_USER:
      console.log(action.payload);
      return {
        ...state,
        item: action.payload
      };
    default:
      return state;
  }
};
export default AuthUserReducer;
