import Cookies from 'js-cookie';
import actions from './actions';

const { LOGIN_BEGIN, LOGIN_SUCCESS, LOGIN_ERR, LOGOUT_BEGIN,
  LOGOUT_SUCCESS, LOGOUT_ERR, GET_USER_PROFILE,
  UPDATE_PROFILE_LOADER } = actions;

const initState = {
  login: Cookies.get('logedIn'),
  loading: false,
  error: null,
  profileData: {},
  updateprofileloader: false,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const AuthReducer = (state = initState, action) => {
  const { type, data, err } = action;

  // console.log(type, data)
  switch (type) {
    case LOGIN_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        login: data,
        loading: false,
      };
    case LOGIN_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case LOGOUT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        login: data,
        loading: false,
      };
    case LOGOUT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case GET_USER_PROFILE:
      return {
        ...state,
        profileData: data
      };
    case UPDATE_PROFILE_LOADER:
      return {
        ...state,
        updateprofileloader: action.payload
      };
    // case UPDATE_PROFILE_LOADER_FALSE:
    //   return {
    //     ...state,
    //     updateprofileloader: false
    //   }
    default:
      return state;
  }
};
export default AuthReducer;
