import React, { lazy } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import DepositTypeList from '../../container/deposit_type/DepositTypeList';
import CreateDepositType from '../../container/deposit_type/CreateDepositType';
import EditDepositType from '../../container/deposit_type/EditDepositType';
import DepositCreate from '../../container/deposit/AddDeposit';
import DepositReport from '../../container/deposit/DepositReport';
import DepositDetails from "../../container/deposit/DepositDetails";
import PendingDepositRequest from "../../container/deposit/PendingDepositRequest";

// const ChartJs = lazy(() => import('../../container/charts/ChartJs'));
const Deposit = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={`${path}/alldeposittypes`} component={DepositTypeList} />
            <Route path={`${path}/newdeposittype`} component={CreateDepositType} />
            <Route path={`${path}/editdeposittype/:id`} component={EditDepositType} />
            <Route path={`${path}/newDeposit`} component={DepositCreate} />
            <Route path={`${path}/editDeposit/:id`} component={DepositCreate} />
            <Route path={`${path}/depositdetails/:id`} component={DepositDetails} />
            <Route path={`${path}/depositReport`} component={DepositReport} />
            <Route path={`${path}/pendingdepositlist`} component={PendingDepositRequest} />
        </Switch>
    );
};

export default Deposit;
