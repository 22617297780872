import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const AddContribution = lazy(() => import( "../../container/susu_model/contribution/AddContribution"));
const ContributionDetails = lazy(() => import( "../../container/susu_model/contribution/ContributionDetails"));
const Contributions = lazy(() => import( "../../container/susu_model/contribution/Contributions"));
const BankPayment = lazy(() => import( "../../container/susu_model/contribution/BankPayment"));
const RetrievePayment = lazy(() => import( "../../container/susu_model/contribution/RetrievePaymentIntent"));
const GroupLists = lazy(() => import( "../../container/susu_model/group/GroupLists"));
const GroupAccounts = lazy(() => import( "../../container/susu_model/group/GroupAccounts"));
const NewGroup = lazy(() => import( "../../container/susu_model/group/NewGroup"));
const EditGroup = lazy(() => import( "../../container/susu_model/group/EditGroup"));
const PendingAccountLists = lazy(() => import( "../../container/susu_model/susu_account/PendingAccountLists"));
const OpenAccount = lazy(() => import('../../container/susu_model/susu_account/OpenAccount'));
const AccountLists = lazy(() => import('../../container/susu_model/susu_account/AccountLists'));
const EditAccount = lazy(() => import('../../container/susu_model/susu_account/EditAccount'));
const WithdrawalList = lazy(() => import('../../container/susu_model/withdrawal/WithdrawalList'));

const SuSuAccount = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={`${path}/managegroup`} component={GroupLists} />
            <Route path={`${path}/newgroup`} component={NewGroup} />
            <Route path={`${path}/editgroup/:id`} component={EditGroup} />
            <Route path={`${path}/newaccount`} component={OpenAccount} />
            <Route path={`${path}/manageaccount`} component={AccountLists} />
            <Route path={`${path}/pendingaccounts`} component={PendingAccountLists} />
            <Route path={`${path}/editaccount/:id`} component={EditAccount} />
            <Route path={`${path}/groupaccounts/`} component={GroupAccounts} />
            <Route path={`${path}/addcontribution`} component={AddContribution} />
            <Route path={`${path}/contributiondetails/:id`} component={ContributionDetails} />
            <Route path={`${path}/managecontribution`} component={Contributions} />
            <Route path={`${path}/bankpayment/:id`} component={BankPayment} />
            <Route path={`${path}/retrievePayment`} component={RetrievePayment} />
            <Route path={`${path}/withdrawallist`} component={WithdrawalList} />
        </Switch>
    );
};

export default SuSuAccount;
