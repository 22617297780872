import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import CustomerDashboard from './customer_dashboard';
import CustomerDeposit from './customer_deposit';
import CustomerWithdraw from './customer_withdraw';
import PaymentMethod from './customer_payment';
import CustomerSupport from './customer_support';
import ProfileRoutes from './customer_profile';
import Agreement from './customer_agreement';
import CustomerSuSuAccount from  './customer_susu_account';

import withAdminLayout from '../../layout/withAdminLayout';
import loader from '../../static/img/loader.gif';

const MarketHistory = lazy(() => import('../../container/customer_portal/market_value_history'));
const TransactionHistory = lazy(() => import('../../container/customer_portal/transaction_history'));

const Customer = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Suspense fallback={
        <div className="spin">
          {/*<Spin size='large' />*/}
          <img src={loader} alt="" style={{width:"10px", height:"10px"}}/>
        </div>
      }>
        <Route path={path} component={CustomerDashboard} />
        <Route path={`${path}/agreement`} component={Agreement} />
        <Route path={`${path}/account`} component={CustomerSuSuAccount} />
        <Route path={`${path}/deposit`} component={CustomerDeposit} />
        <Route path={`${path}/withdraw`} component={CustomerWithdraw} />
        <Route path={`${path}/marketvaluehistory`} component={MarketHistory} />
        <Route path={`${path}/transactionhistory`} component={TransactionHistory} />
        <Route path={`${path}/support`} component={CustomerSupport} />
        <Route path={`${path}/payment`} component={PaymentMethod} />
        <Route path={`${path}/profile`} component={ProfileRoutes} />
      </Suspense>

    </Switch>
  );
};

export default withAdminLayout(Customer);
