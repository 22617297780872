import { combineReducers } from 'redux';
// import themeUsersReducer from './themeUsers/reducers';
import authReducer from './authentication/reducers';
import ChangeLayoutMode from './themeLayout/reducers';
// import { teamReducer } from './profile/team/reducers';
// import Profile from './profile/reducers';
import AuthUserReducer from './users/reducers';
import Common from './common/reducers';
import AdminSuSuAccountReducer from "./admin_susu_model/reducers";

const rootReducers = combineReducers({
  // userRoles: UserRoles,
  // themeUsers: themeUsersReducer,
  // users: userReducer,
  // userGroup: userGroupReducer,
  authUser: AuthUserReducer,
  // team: teamReducer,
  auth: authReducer,
  ChangeLayoutMode,
  // Profile,
  common:Common,
  adminSuSuAccount: AdminSuSuAccountReducer
});

export default rootReducers;
