import React, {useEffect, useState} from 'react';
import {Col, DatePicker, Form, Input, Modal, notification, Row, Select} from 'antd';
import axios from "axios";
import "../customStyle.css";
import Helper from "../../helpers/Helper";
import moment from "moment";
import {ApiUrl} from "../ApiUrl";


const {Option} = Select;

const ChangeMarketValue = ({visible, isShow}) => {

    const [loading, setLoading] = useState("Save");
    const [form] = Form.useForm();
    const [customers, setCustomers] = useState([]);
    const [errors, setErrors] = useState({});
    const [Date, setDate] = useState({});
    const [state, setState] = useState({});

    let redirectTo = "/admin/investment/marketValueHistory";

    useEffect(() => {
        getCustomer();
        Helper.Title("Change Market Value");
    }, []);

    const handleDate = (value) => {
        setDate(moment(value).format('YYYY-MM-DD'))
    }

    const getCustomer = () => {
        axios.get("customer")
            .then((res) => {
                console.log(res.data);
                if (res.data.status === 1) {
                    setCustomers(res.data.data);
                }
            })
            .catch((error) => {
                console.log(error)
            });
    }


    const handleChange = (e) => {

        setState({
            ...state, [e.target.name]: e.target.value
        });
    }


    const handleSubmit = (values) => {
        let data = {
            ...values, dob: moment(values['dob']).format('YYYY-MM-DD'),
        }
        setLoading(true)
        axios.post(ApiUrl.ADD_CHANGE_MARKET_VALUE, data)
            .then(res => {
                if (res.data.status === 1) {
                    isShow(false)

                    notification["success"]({
                        message: 'Success.', description: res.data.msg,
                    });
                } else {
                    notification["warning"]({
                        message: 'Warning.', description: res.data.msg,
                    });
                }
                setLoading(false)
            })
            .catch(errors => console.log(errors))
    }

    const handleCancel = () => {
        isShow(false)
    }

    return (<>

        <Modal
            title="Change Market Value"
            footer={false}
            visible={visible}
            onCancel={handleCancel}
        >
            <Form form={form} name="input-form" layout="vertical" onFinish={handleSubmit}>
                <Row gutter={24}>

                    <Col md={24} xs={24}>
                        <Form.Item
                            name="date"
                            label="Date"
                            rules={[{
                                required: true, message: "Date is required"
                            }]}
                        >
                            <DatePicker format={"MM-DD-YYYY"} style={{width: '100%'}}/>
                        </Form.Item>
                    </Col>

                    <Col md={24} xs={24}>
                        <Form.Item
                            name="investor_id"
                            label="Customer"
                            rules={[{
                                required: true, message: "Customer Name is required"
                            }]}
                        >
                            <Select
                                className="sDash_fullwidth-select"
                                showSearch
                                placeholder="Select an option"
                            >
                                {customers.map((customer, index) => <option value={customer.id}
                                                                            key={index}>{customer.name}</option>)}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col md={24} xs={24}>
                        <Form.Item
                            name="current_value"
                            label="Current Market Value"
                            rules={[{
                                required: true, message: "Current Market Value is required"
                            }]}
                        >
                            <Input style={{width: '100%'}} placeholder="Current Market Value"/>
                        </Form.Item>
                    </Col>

                    <Col md={24} xs={24}>
                        <Form.Item
                            name="remark"
                            label="Remark"
                            rules={[{
                                required: false, message: "Remark is required"
                            }]}
                        >
                            <Input style={{width: '100%'}} placeholder="Remark"/>
                        </Form.Item>
                    </Col>
                </Row>

                <Row className={`mt-20`}>

                    <Col md={24} xs={24} style={{textAlign: "center"}}>
                        <div className="sDash_form-action">
                            <button type="submit" className="btn btn-success" size="large" id="submit">
                                Submit
                            </button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Modal>
    </>);
};

export default ChangeMarketValue;
