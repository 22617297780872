import React, { lazy } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

const MessageLists = lazy(() => import('../../container/message/MessageList'));
const ShowMessage = lazy(() => import('../../container/message/ShowMessage'));
const SendReplyMessage = lazy(() => import('../../container/message/SendReplyMessage'));

const CustomerSupport = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={`${path}/messagelists`} component={MessageLists} />
            <Route path={`${path}/showmessage/:id`} component={ShowMessage} />
            <Route path={`${path}/replymessage/:id`} component={SendReplyMessage} />
        </Switch>
    );
};

export default CustomerSupport;
