import React, {Component} from 'react';
import {Main} from "../styled";
import {PageHeader} from "../../components/page-headers/page-headers";
import {Button} from "../../components/buttons/buttons";
import {Link} from "react-router-dom";
import {Col, notification, Row} from "antd";
import {Cards} from "../../components/cards/frame/cards-frame";
import FontAwesome from "react-fontawesome";
import * as PropTypes from "prop-types";
import axios from "axios";
import "../customStyle.css";


FontAwesome.propTypes = {
    icon: PropTypes.string, size: PropTypes.number
};

class DepositTypeList extends Component {
    state = {
        typeList: [],
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.getInvestmentLevel();
    }

    getInvestmentLevel = async () => {

        return await axios.get("deposittype")
            .then((response) => {
                if (response.data.status === 1) {
                    this.setState({
                        typeList: response.data.data
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    deleteLevel = (id) => {
        if (confirm('Are you sure you want to delete this data?')) {
            axios.delete("/investmenttype/delete/" + id)
                .then(res => {
                    if (res.data.status === 1) {
                        notification["warning"]({
                            message: 'Warning.', description: res.data.msg,
                        });
                    } else {
                        const isNotId = type => type.id !== id;
                        const updateToDos = this.state.typeList.filter(isNotId);
                        this.setState({
                            typeList: updateToDos,
                        });
                        notification["success"]({
                            message: 'Success.', description: res.data.msg,
                        });
                    }
                })
                .catch((error) => {
                    notification["error"]({
                        message: 'Error.', description: error.res.data.msg,
                    });
                })
        } else {
            return false;
        }
    };

    render() {

        return (<>
                <PageHeader
                    ghost
                    title="Deposit Type"
                    buttons={[<Button className="btn-add_new" size="default" type="primary" key="1">
                        <Link to="/admin/investment/createinvestmentLevel">
                            <FontAwesome className={`fa fa-plus`} style={{marginRight: '5px'}}/> Add
                        </Link>
                    </Button>,]}
                />

                <Main>
                    <Row gutter={15}>
                        <Col md={24}>
                            <Cards title="Deposit Type List">
                                <table className="table table-bordered customTable customStatus">
                                    <thead>
                                    <tr>
                                        <th>Sl No.</th>
                                        <th>Name</th>
                                        <th>Details</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.typeList.map((type, index) => <tr key={index}>
                                        <td>{++index}</td>
                                        <td>{type.name}</td>
                                        <td>{type.description}</td>
                                        <td>
                                            {type.status === 0 ? (<button
                                                    className="btn btn-danger btn-sm customStatus">Inactive</button>) : (

                                                <button
                                                    className="btn btn-success btn-sm customStatus">Active</button>)}
                                        </td>
                                        <td>
                                            <Link to={`editdeposittype/${type.id}`}
                                                  className="btn btn-success mr-2 customAction">
                                                <FontAwesome className={`fa fa-pencil`}/>
                                            </Link>
                                            <button className="btn btn-danger customAction"
                                                    onClick={() => this.deleteLevel(type.id)}>
                                                <FontAwesome className={`fa fa-trash`}/>
                                            </button>
                                        </td>
                                    </tr>)}
                                    </tbody>
                                </table>
                            </Cards>
                        </Col>
                    </Row>
                </Main>
            </>);
    }
}

export default DepositTypeList;