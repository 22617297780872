import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import './static/scss/styles.scss'
import './container/dashboard/Deshboard.scss';
import axios from "axios";
import * as serviceWorker from './serviceWorker';

 // axios.defaults.baseURL = 'http://127.0.0.1:8000/api/';
//  axios.defaults.baseURL = 'http://192.168.68.149:8000/api/';
//  axios.defaults.baseURL = 'https://gtapi.goldentreasuresinc.com/api/';
 axios.defaults.baseURL = 'https://gtiapi.cisstaging.com/api/';

if(localStorage.getItem('userInfo')) {
    const authData = JSON.parse(localStorage.getItem('userInfo'));

    axios.defaults.headers.AUTHORIZATION = `${authData['userID']+':'+authData['password']}`;
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
