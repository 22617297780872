import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Dashboard from './dashboard';
import DeveloperRoutes from './developer';

import UserRoute from './users';
import CustomerRoute from "./customer";
import InvestmentRoute from "./investment";
import DepositRoute from "./deposit";
import WithdrawRoute from "./withdraw";
import PaymentRoute from "./payment";
import ReportRoute from "./report";
import ProfileSettings from "./profile";
import CustomerSupport from "./customer_support";
import withAdminLayout from '../../layout/withAdminLayout';
import loader from '../../static/img/loader.gif';
import SuSuAccount from "./susu_account";

const Admin = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Suspense fallback={
        <div className="spin">
          {/*<Spin size='large' />*/}
          <img src={loader} alt="" style={{width:"100px", height:"100px"}}/>
        </div>
      }>
        <Route path={path} component={Dashboard} />
        <Route path={`${path}/developer`} component={DeveloperRoutes} />
        <Route path={`${path}/users`} component={UserRoute} />
        <Route path={`${path}/account`} component={SuSuAccount} />
        <Route path={`${path}/customer`} component={CustomerRoute} />
        <Route path={`${path}/investment`} component={InvestmentRoute} />
        <Route path={`${path}/deposit`} component={DepositRoute} />
        <Route path={`${path}/withdraw`} component={WithdrawRoute} />
        <Route path={`${path}/payment`} component={PaymentRoute} />
        <Route path={`${path}/report`} component={ReportRoute} />
        <Route path={`${path}/profile`} component={ProfileSettings} />
        <Route path={`${path}/customersupport`} component={CustomerSupport} />
      </Suspense>

    </Switch>
  );
};

export default withAdminLayout(Admin);
