import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const SendMessage = lazy(() => import('../../container/customer_portal/customer_support/SendMessage'));
const MessageList = lazy(() => import('../../container/customer_portal/customer_support/MessageList'));
const MessageDetails = lazy(() => import('../../container/customer_portal/customer_support/MessageDetails'));
const ReplyMessage = lazy(() => import('../../container/customer_portal/customer_support/ReplyMessage'));

const CustomerPayment = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={`${path}/sendmessage`} component={SendMessage} />
            <Route path={`${path}/messagedetails/:id`}component={MessageDetails} />
            <Route path={`${path}/messagelist`} component={MessageList} />
            <Route path={`${path}/replymessage/:id`} component={ReplyMessage} />
        </Switch>
    );
};

export default CustomerPayment;
