import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader/root';
import { Provider, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import store from './redux/store';
import Admin from './routes/admin';
import Customer from './routes/customer';
import Auth from './routes/auth';
import './static/css/style.css';
import config from './config/config';
import ProtectedRoute from './components/utilities/protectedRoute';
import LocalStorageData from "./helpers/LocalStorageData";

const { theme } = config;

const ProviderConfig = () => {
    const { rtl, isLoggedIn, topMenu, darkMode } = useSelector(state => {
        return {
            darkMode: state.ChangeLayoutMode.data,
            rtl: state.ChangeLayoutMode.rtlData,
            topMenu: state.ChangeLayoutMode.topMenu,
            isLoggedIn: state.auth.login,
        };
    });

    const [path, setPath] = useState(window.location.pathname);

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            setPath(window.location.pathname);
        }
        // eslint-disable-next-line no-return-assign
        return () => (unmounted = true);
    }, [setPath]);

return (
        <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
            <ThemeProvider theme={{ ...theme, rtl, topMenu, darkMode }}>
                <Router basename={process.env.PUBLIC_URL}>
                    {!isLoggedIn ? <Route path="/" component={Auth} /> :
                        ( parseInt(LocalStorageData.AppStorage('area')) === 1 ?
                            <ProtectedRoute path="/admin" component={Admin} /> :
                            <ProtectedRoute path="/customer" component={Customer} />
                        )
                    }
                    {/*{redirect()}*/}

                    {/*{isLoggedIn && (path === process.env.PUBLIC_URL || path === `${process.env.PUBLIC_URL}/`) && (*/}
                    {/*    <Redirect to="/admin" />*/}
                    {/*)}*/}
                    {isLoggedIn && (path === process.env.PUBLIC_URL || path === `${process.env.PUBLIC_URL}/`) && (
                        parseInt(LocalStorageData.AppStorage('area')) === 1 ?
                        <Redirect to="/admin" /> : <Redirect to="/customer" />
                    )}
                </Router>
            </ThemeProvider>
        </ConfigProvider>
    );
};

function App() {
    return (
        <Provider store={store}>
            <ProviderConfig />
        </Provider>
    );
}

export default hot(App);
