import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const RetrievePayment = lazy(() => import("../../container/customer_portal/susu_account/contribution/RetrievePaymentIntent"));
const BankPayment = lazy(() => import("../../container/customer_portal/susu_account/contribution/BankPayment"));
const AddContribution = lazy(() => import( "../../container/customer_portal/susu_account/contribution/AddContribution"));
const Contributions = lazy(() => import("../../container/customer_portal/susu_account/contribution/Contributions"));
const ContributionDetails = lazy(() => import("../../container/customer_portal/susu_account/contribution/ContributionDetails"));
const ContributionEdit = lazy(() => import("../../container/customer_portal/susu_account/contribution/EditContribution"));
const OpenAccount = lazy(() => import('../../container/customer_portal/susu_account/OpenAccount'));
const AccountLists = lazy(() => import('../../container/customer_portal/susu_account/AccountLists'));
const EditAccount = lazy(() => import('../../container/customer_portal/susu_account/EditAccount'));
const WithdrawalRequest = lazy(() => import('../../container/customer_portal/susu_account/withdrawal/WithdrawalRequest'));
const WithdrawalList = lazy(() => import('../../container/customer_portal/susu_account/withdrawal/WithdrawalList'));

const CustomerSuSuAccount = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={`${path}/openaccount`} component={OpenAccount} />
            <Route path={`${path}/manageaccount`} component={AccountLists} />
            <Route path={`${path}/editaccount/:id`} component={EditAccount} />
            <Route path={`${path}/addcontribution`} component={AddContribution} />
            <Route path={`${path}/managecontribution`} component={Contributions} />
            <Route path={`${path}/contributiondetails/:id`} component={ContributionDetails} />
            <Route path={`${path}/contributionedit/:id`} component={ContributionEdit} />
            <Route path={`${path}/bankpayment/:id`} component={BankPayment} />
            <Route path={`${path}/retrievePayment`} component={RetrievePayment} />

            <Route path={`${path}/newwithdrawalrequest`} component={WithdrawalRequest} />
            <Route path={`${path}/withdrawallist`} component={WithdrawalList} />
            {/*<Route path={`${path}/editaccount/:id`} component={EditAccount} />*/}
        </Switch>
    );
};

export default CustomerSuSuAccount;
