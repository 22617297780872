import moment from "moment";
import {notification} from "antd";

class Helper {
    Title = (title) => {
        return document.title = title;
    }

    FullName = (first_name, last_name) => {
        return first_name + " " +last_name;
    }

    CurrencyFormat = (x) => {
        let number = parseFloat(x).toFixed(2);
        return "$" + number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    DateFormat = (data) => {
        return moment(data).format("MM/DD/YYYY");
    }

    PaymentType = (id) => {
        if(id === 1) {
            return "Bank Account";
        }
        else if (id === 2) {
            return 'Card';
        }
        else {
            return "";
        }
    }

    CreatedDateFormat = (data) => {
        return moment(data).format("MM/DD/YYYY hh:mm:ss")
    }

    // DateFormat = (data) => {
    //     return moment(data).format("MM/DD/YYYY")
    // }

    TimeFormat = (data) => {
        return moment(data).format("hh:mm:ss")
    }

    GetAccountNoLastFourDigit = (data) => {
        let len = parseInt(data);
        return new Array(data.length-3).join('*') +
            data.substr(data.length-4, 4);
    }

    GetDecodeNumber = (data) => {
        let decode = atob(data)
        return this.GetAccountNoLastFourDigit(decode);
    }

    StatusButton = (data) => {
        let html = "";
        if (data == 0) {
            html += `<button className="btn btn-danger btn-sm customStatus">Inactive</button>`;
        } else {
            html += `<button className="btn btn-success btn-sm customStatus">Active</button>`;
        }

        return html;
    }

    strippedString = this.StatusButton().replace(/(<([^>]+)>)/gi, "");

    GetQueryStrings = (values) => {
        const qs = Object.keys(values).map(key =>
            {
                if(values[key] === undefined || values[key] === "" || values[key] === null) {
                    // console.log(key)
                    return `${key}=${""}`
                }
                else {
                    // console.log(key+":"+values[key]);
                    if (key === 'date' || key === 'from_date' || key === 'to_date') {
                        return `${key}=${this.DateFormat(values[key])}`
                    }
                    // else if(key === 'from_date'){
                    //     return `${key}=${this.DateFormat(values[key])}`
                    // }
                    // else if(key === 'to_date'){
                    //     return `${key}=${this.DateFormat(values[key])}`
                    // }
                    else {
                        return `${key}=${values[key]}`
                    }
                }
            }
        ).join('&');

        console.log(qs)

        return qs.replaceAll("undefined", "");
    }

    Notification = (type, msg) => {
        if(type === 'success'){
            notification["success"]({
                message: 'Success.',
                description: msg,
            });
        }
        else if(type === 'warning'){
            notification["warning"]({
                message: 'Warning.',
                description: msg,
            });
        }
        else if(type === 'info'){
            notification["info"]({
                message: 'Info.',
                description: msg,
            });
        }
        else if(type === 'error'){
            notification["Error"]({
                message: 'Error.',
                description: msg,
            });
        }
    }

    AccountDuration = (value) => {
        let data = '';
        if(value === 6){
            data = "6 months";
        }
        else if( value === 12){
            data = "1 year"
        }
        else if( value === 24){
            data = "2 year"
        }
        else if( value === 36){
            data = "3 year"
        }
        else if( value === 48){
            data = "4 year"
        }
        else if( value === 60){
            data = "5 year"
        }

        return data;
    }

    PaymentType = (type) => {
        let data = '';
        if(type === 1){
            data = "Weekly";
        }
        else if(type === 2){
            data = "Bi-Weekly";
        }
        else if(type === 3){
            data = "Monthly"
        }

        return data
    }

    CardExpDate = (data) => {
        let arr = data.split("");
        let month = arr[0]+arr[1];
        let year = arr[2]+arr[3];

        return month + "/" + year
    }
}

export default Helper = new Helper();

