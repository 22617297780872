import Cookies from 'js-cookie';
import actions from './actions';
import axios from "axios";
import API from "./../../config/api/index";
import LocalStorageData from "../../helpers/LocalStorageData";
import {notification} from "antd";

const { loginBegin, loginSuccess, loginErr, logoutBegin, logoutSuccess, logoutErr, getUserProfile, updateUserProfile, updateProfileLoader } = actions;

// const login = () => {
//   //console.log(userID);
//   return async dispatch => {
//     try {
//       dispatch(loginBegin());
//       setTimeout(() => {
//         Cookies.set('logedIn', true);
//         return dispatch(loginSuccess(true));
//       }, 1000);
//     } catch (err) {
//       dispatch(loginErr(err));
//     }
//   };
//
// };

const login = ({username, password}) => {
    console.log(username, password);
    return async dispatch => {
        const userdata = {
            "userID": username,
            "password": password
        }
        dispatch(loginBegin());
        axios.post('login', {
                userid: username,
                password: password
            })
            .then((res) => {
                console.log("data:", res.data);
                if (res.data.status === 200) {
                    localStorage.setItem("userData", JSON.stringify(res.data.userdata));
                    localStorage.setItem("userInfo", JSON.stringify(userdata));
                    // localStorage.setItem("token", data.token.access_token);
                    axios.defaults.headers.common['authorization'] = userdata['userID']+":"+userdata['password'];
                    // dispatch({type: FETCH_SUCCESS});
                    // dispatch({type: USER_TOKEN_SET, payload: data.token.access_token});
                    // dispatch({type: USER_DATA, payload: data.user});
                } else {
                    console.log("Error");
                }
            }).catch(error => {
            dispatch(loginErr(error));
            console.log("Error****:", error.message);
        });
    }
}

const logOut = () => {
    return async dispatch => {
        try {
            dispatch(logoutBegin());
            Cookies.remove('logedIn');
            dispatch(logoutSuccess(null));
        } catch (err) {
            dispatch(logoutErr(err));
        }
    };
};

const GetUserProfile = () => {
    return async dispatch => {
        dispatch(updateProfileLoader(true))
        const userId = LocalStorageData.AppStorage('id');
        // axios.get(`mycustomerprofile/` + userId)
        axios.get(`userprofileinfo`)
            .then(res => {
                if (res.data.status === 1) {
                    // console.log(res.data.data);
                    // setState(res.data.data);
                    dispatch(getUserProfile(res.data.data));
                    
                } else {
                    console.log("Error");
                }
                dispatch(updateProfileLoader(false))
            })
            .catch(error => {
                console.log(error);
                dispatch(updateProfileLoader(false))
            });
    }
}

const UpdateUserProfile = (values) => {
    return async dispatch => {
        dispatch(updateProfileLoader(true))
        axios.post(`updateuserprofile`, values)
            .then(res => {
                if(res.data.status === 1) {
                    dispatch(getUserProfile(res.data.data))
                    notification["success"]({
                        message: 'Success.',
                        description: res.data.msg,
                    });
                } else {
                    notification["warning"]({
                        message: 'Error.',
                        description: res.data.msg,
                    });
                }
                dispatch(updateProfileLoader(false))
            })
            .catch(errors => {
                // setErrors(errors.response.data.errors)
                // setLoading("Save");
                
                console.log(errors)
                dispatch(updateProfileLoader(false))
            })
    }
}

const UpdateAdminProfile = (values, userID) => {
    return async dispatch => {
        dispatch(updateProfileLoader(true))
        axios.post(`updatemyprofile/` + userID, values)
            .then(res => {
                if(res.data.status === 1) {
                    dispatch(getUserProfile(res.data.data))
                    notification["success"]({
                        message: 'Success.',
                        description: res.data.msg,
                    });
                } else {
                    notification["warning"]({
                        message: 'Error.',
                        description: res.data.msg,
                    });
                }
                dispatch(updateProfileLoader(false))
            })
            .catch(errors => {
                // setErrors(errors.response.data.errors)
                // setLoading("Save");
                
                console.log(errors)
                dispatch(updateProfileLoader(false))
            })
    }
}
export { login, logOut, GetUserProfile, UpdateUserProfile, UpdateAdminProfile };
