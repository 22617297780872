const actions = {
  LOGIN_BEGIN: 'LOGIN_BEGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERR: 'LOGIN_ERR',

  LOGOUT_BEGIN: 'LOGOUT_BEGIN',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_ERR: 'LOGOUT_ERR',

  GET_USER_PROFILE: 'GET_USER_PROFILE',
  UPDATE_USER_PROFILE: 'UPDATE_USER_PROFILE',
  UPDATE_PROFILE_LOADER: 'UPDATE_PROFILE_LOADER',
  // UPDATE_PROFILE_LOADER_FALSE: 'UPDATE_PROFILE_LOADER_FALSE',

  loginBegin: () => {
    return {
      type: actions.LOGIN_BEGIN,
    };
  },

  loginSuccess: data => {
    return {
      type: actions.LOGIN_SUCCESS,
      data,
    };
  },

  loginErr: err => {
    return {
      type: actions.LOGIN_ERR,
      err,
    };
  },

  logoutBegin: () => {
    return {
      type: actions.LOGOUT_BEGIN,
    };
  },

  logoutSuccess: data => {
    return {
      type: actions.LOGOUT_SUCCESS,
      data,
    };
  },

  logoutErr: err => {
    return {
      type: actions.LOGOUT_ERR,
      err,
    };
  },

  getUserProfile: data => {
    return {
      type: actions.GET_USER_PROFILE,
      data,
    };
  },

  updateUserProfile: data => {
    return {
      type: actions.UPDATE_USER_PROFILE,
      data,
    };
  },
  
  updateProfileLoader: data => {
    return {
      type: actions.UPDATE_PROFILE_LOADER,
      payload: data
    };
  },
  // updateProfileLoaderFalse: data => {
  //   return {
  //     type: actions.UPDATE_PROFILE_LOADER_FALSE
  //   };
  // }
};

export default actions;
