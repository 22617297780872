import React, {useEffect, useState} from 'react';
import { Avatar } from 'antd';
import { Link } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { InfoWraper, NavAuth, UserDropDwon } from './auth-info-style';
import { Popover } from '../../popup/popup';
import {GetUserProfile, logOut} from '../../../redux/authentication/actionCreator';
import API from "../../../config/api";

const AuthInfo = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    flag: 'english',
  });
  // console.log(Object.values(API)[0].imageURL)
  const { flag } = state;

    const {profileData} = useSelector(({auth}) => auth);
    console.log("authInfo", profileData)

    useEffect(() => {
        dispatch(GetUserProfile())
    }, []);

  const SignOut = e => {
    e.preventDefault();
    localStorage.clear()
    dispatch(logOut());
  };

  // style={{ boxShadow: '0px -1px 5px 0px rgba(0,0,0,0.75)' }}
   const userData = JSON.parse(localStorage.getItem("userData"));
   console.log("userData", userData)

  const userContent = (
    <UserDropDwon style={{ borderTop: '0px solid #e1e1e1' }}>
      <div className="user-dropdwon" style={{width:"100px"}}>
        {/* <figure className="user-dropdwon__info">
          <figcaption>
            {/*{*/}
            {/*  userData['name'] && (*/}
            {/*      <Heading as="h5">{userData['name']}</Heading>*/}
            {/*  )*/}
            {/*}*/}
              {/* {profileData?.name}

          </figcaption> 
        </figure>  */}
        <ul className="user-dropdwon__links">
          { profileData?.area === 1 && (
                  <>
                  <li>
                      <Link to="/admin/profile/profileSettings">
                          <FeatherIcon icon="user" /> Profile
                      </Link>
                  </li>
                <li>
                  <Link to="/admin/profile/profileSettings/settings">
                    <FeatherIcon icon="settings" /> Settings
                  </Link>
                </li>
                  </>
              )
          }
            { profileData?.area !== 1 && (
                <>
                    <li>
                        <Link to="/customer/profile/profileSettings/profile">
                            <FeatherIcon icon="user" /> Profile
                        </Link>
                    </li>
                </>
            )
            }
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <FeatherIcon icon="log-out" /> Sign Out
        </Link>
      </div>
    </UserDropDwon>
  );

  const onFlagChangeHandle = value => {
    setState({
      ...state,
      flag: value,
    });
  };

  const country = (
    <NavAuth>
      <Link onClick={() => onFlagChangeHandle('english')} to="#">
        <img src={require('../../../static/img/flag/english.png')} alt="" />
        <span>English</span>
      </Link>
      <Link onClick={() => onFlagChangeHandle('germany')} to="#">
        <img src={require('../../../static/img/flag/germany.png')} alt="" />
        <span>Germany</span>
      </Link>
      <Link onClick={() => onFlagChangeHandle('spain')} to="#">
        <img src={require('../../../static/img/flag/spain.png')} alt="" />
        <span>Spain</span>
      </Link>
      <Link onClick={() => onFlagChangeHandle('turky')} to="#">
        <img src={require('../../../static/img/flag/turky.png')} alt="" />
        <span>Turky</span>
      </Link>
    </NavAuth>
  );

  // console.log("imageUrl", Object.values(API)[0].imageURL+`upload/profile/${profileData?.photo}`)

  return (
    <InfoWraper >
      {/*<Message />*/}
      {/*<Notification />*/}

      {/*<Settings />*/}
      {/*<Support />*/}
      {/*<div className="nav-author">*/}
      {/*  <Dropdown placement="bottomRight" content={country} trigger="click">*/}
      {/*    <Link to="#" className="head-example">*/}
      {/*      <img src={require(`../../../static/img/flag/${flag}.png`)} alt="" />*/}
      {/*    </Link>*/}
      {/*  </Dropdown>*/}
      {/*</div>*/}

      <div className="nav-author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="head-example">
            {/*<Avatar src="https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png" />*/}
              <p style={{ margin: '1px' }}>
                  {
                      userData?.area === 1 ?
                          <>
                            <Avatar src={Object.values(API)[0].imageURL+`${profileData?.image}`} style={{ display:"inline-block" }}/>{profileData?.name}
                          </>
                        :
                          <>
                              <Avatar src={Object.values(API)[0].imageURL+`upload/profile/${profileData?.photo}`} style={{ display:"inline-block" }}/>{profileData?.name}
                          </>
                  }
              </p>
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
};

export default AuthInfo;
